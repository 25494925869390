<template>
  <div>
    <Spinner></Spinner>
  </div>
</template>

<script>
import mysohoAPI from "@/api/external/mysoho.js";
import Spinner from "@/components/basis/spinner.vue";

export default {
  data() {
    return {};
  },
  methods: {},
  components: {
    Spinner
  },
  mounted() {
    mysohoAPI.getTemporalKey().then(res => {
      if (res.status == 201) {
        // Init
        const tempkey = res.data.tempKey;
        const redirectUrl =
          process.env.VUE_APP_BASE_API +
          "/external/mysoho/auth?tempkey=" +
          tempkey;

        // Save callback URL
        sessionStorage.setItem(
          "mysoho_interlock_callback",
          this.$route.query.callback
        );

        // Save the temporal key in the session storage
        sessionStorage.setItem("mysoho_temporal_key", tempkey);
        setTimeout(() => {
          location.href =
            process.env.VUE_APP_EXTERNAL_MYSOHO_LOGIN_URL +
            "?service=qpick&redirect_uri=" +
            encodeURIComponent(btoa(redirectUrl));
        }, 3000);
      }
    });
  }
};
</script>

<style></style>
